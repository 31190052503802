import axios from 'axios'
var currentURL = window.location.href
var getTenant = currentURL.split('.')
console.log('getTenant3', getTenant)
const AxiosInterceptor = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    tenant: 'tsacdemo',
  },
})
AxiosInterceptor.interceptors.request.use((config) => {
  const token = localStorage.jwtToken
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})
AxiosInterceptor.interceptors.response.use(
  (res) => {
    return res?.data
  },
  (error) => {
    const token = localStorage.jwtToken
    if (error?.response?.data?.user_msg === 'inavlid token' || !!token === false) {
      localStorage.clear()
      console.clear()
      document.location.href = '/'
    }
    return error
  },
)

export default AxiosInterceptor
